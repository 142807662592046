import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";

type MarkdownProps = {
  file: string;
};

const Markdown: (props: MarkdownProps) => JSX.Element = ({ file }) => {
  const [content, setContent] = useState("");

  useEffect(() => {
    const requestPath = `${file}?${Date.now()}`;
    fetch(requestPath)
      .then((res) => res.text())
      .then((text) => setContent(text));
  }, [file]);

  return <ReactMarkdown>{content}</ReactMarkdown>;
};

export default Markdown;
