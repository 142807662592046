import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import Privacy from "../screens/privacy/Privacy";
import License from "../screens/license/License";
import {
  FooterContainer,
  FooterContent,
  StyledLink,
  VersionText,
} from "./AppFooter.styles";
import { versionInfo } from "../shared/versionInfo";

const year = new Date().getFullYear();

const AppFooter = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "screen.welcomeHome" });
  const [openPrivacyModal, setOpenPrivacyModal] = useState(false);
  const [openLicenseModal, setOpenLicenseModal] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.hash === "#privacy") {
      setOpenPrivacyModal(true);
    }
  }, [location]);

  return (
    <>
      <FooterContainer>
        <FooterContent>
          <Typography variant="body2">
            &copy; {year} {t("purplScientific")}
          </Typography>
        </FooterContent>
        <FooterContent>
          <StyledLink>
            <Typography onClick={() => setOpenPrivacyModal(true)}>
              {t("privacyPolicy")}
            </Typography>
          </StyledLink>
          <StyledLink>
            <Typography onClick={() => setOpenLicenseModal(true)}>
              {t("licenseAgreement")}
            </Typography>
          </StyledLink>
          <div>
            <VersionText>
              v{versionInfo.version}-{versionInfo.commitHash}
            </VersionText>
          </div>
        </FooterContent>
      </FooterContainer>
      <Privacy
        open={openPrivacyModal}
        handleClose={() => setOpenPrivacyModal(false)}
      />
      <License
        open={openLicenseModal}
        handleClose={() => setOpenLicenseModal(false)}
      />
    </>
  );
};

export default AppFooter;
