import { Dialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Markdown from "../../shared/components/Markdown/MarkDown";

interface LicenseProps {
  open: boolean;
  handleClose: () => void;
}

const License = ({ open, handleClose }: LicenseProps) => {
  return (
    <Dialog fullWidth maxWidth="laptop" open={open} onClose={handleClose}>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Markdown file="/license.md" />
      </DialogContent>
    </Dialog>
  );
};

export default License;
